import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [
	{
	  path: '/',
	  name: 'home',
	  redirect: '/home',
	  component: () => import('@/views/introduce_pc/home/home.vue'),
	},
	{
		path: '/home',
		component: () => import('@/views/detail-PC/home/home.vue'),
	},
	{
		path: '/home/apiAccess',
		component: () => import('@/views/detail-PC/home/apiAccess.vue'),
	},
	{
		path: '/project/operate',
		component: () => import('@/views/detail-PC/project/operate.vue'),
	},
	{
		path: '/project/evolution',
		component: () => import('@/views/detail-PC/project/evolution.vue'),
	},
	{
		path: '/project/flow',
		component: () => import('@/views/detail-PC/project/flow.vue'),
	},
	{
		path: '/project/OTO',
		component: () => import('@/views/detail-PC/project/OTO.vue'),
	},
	{
		path: '/productCenter/center',
		component: () => import('@/views/detail-PC/productCenter/center.vue'),
	},
	{
	  path: '/control/control',
	  component: () => import('@/views/detail-PC/control/control.vue')
	},
	{
	  path: '/control/apimMnage',
	  component: () => import('@/views/detail-PC/control/apimMnage.vue')
	},
	{
	  path: '/control/found',
	  component: () => import('@/views/detail-PC/control/found.vue')
	},
	{
	  path: '/news/message',
	  component: () => import('@/views/detail-PC/news/message.vue')
	},
	{
	  path: '/news/detail',
	  component: () => import('@/views/detail-PC/news/detail.vue')
	},
	{
		path: '/fileCenter/center',
		component: () => import('@/views/detail-PC/fileCenter/center.vue'),
	},
	{
		path: '/businessCenter/center',
		component: () => import('@/views/detail-PC/businessCenter/center.vue'),
	},
	{
		path: '/about/aboutUs',
		component: () => import('@/views/detail-PC/about/aboutUs.vue'),
	},
	{
		path: '/download/downLoadPre',
		component: () => import('@/views/detail-PC/download/downLoadPre.vue'),
	},
	{
	  path: '/login/login',
	  component: () => import('@/views/detail-PC/login/login.vue')
	},
	{
	  path: '/login/register',
	  component: () => import('@/views/detail-PC/login/register.vue')
	},
	// //移动端
	{
		path: '/homeWap',
		component: () => import('@/views/detail-Wap/home/homeWap.vue'),
	},
	{
		path: '/home/apiAccessWap',
		component: () => import('@/views/detail-Wap/home/apiAccessWap.vue'),
	},
	{
		path: '/project/operateWap',//项目运营
		component: () => import('@/views/detail-Wap/project/operateWap.vue'),
	},
	{
		path: '/project/evolutionWap',//Bass演变
		component: () => import('@/views/detail-Wap/project/evolutionWap.vue'),
	},
	{
		path: '/project/flowWap',//流量转化
		component: () => import('@/views/detail-Wap/project/flowWap.vue'),
	},
	{
		path: '/project/OTOWap',//线上线下
		component: () => import('@/views/detail-Wap/project/OTOWap.vue'),
	},
	{
		path: '/productCenter/centerWap',//产品中心
		component: () => import('@/views/detail-Wap/productCenter/centerWap.vue'),
	},
	// {
	//   path: '/control/controlWap',
	//   component: () => import('@/views/detail-Wap/control/controlWap.vue')
	// },
	// {
	//   path: '/control/apimMnageWap',
	//   component: () => import('@/views/detail-Wap/control/apimMnageWap.vue')
	// },
	{
		path: '/fileCenter/centerWap',//文档中心
		component: () => import('@/views/detail-Wap/fileCenter/centerWap.vue'),
	},
	{
		path: '/businessCenter/centerWap',//商务中心
		component: () => import('@/views/detail-Wap/businessCenter/centerWap.vue'),
	},
	{
		path: '/about/aboutUsWap',//关于我们
		component: () => import('@/views/detail-Wap/about/aboutUsWap.vue'),
	},
	{
		path: '/download/downLoadPreWap',//下载专区
		component: () => import('@/views/detail-Wap/download/downLoadPreWap.vue'),
	},
	// {
	//   path: '/login/loginWap',
	//   component: () => import('@/views/detail-Wap/login/loginWap.vue')
	// },
	// {
	//   path: '/login/registerWap',
	//   component: () => import('@/views/detail-Wap/login/registerWap.vue')
	// },
	// {
	//   path: '/about',
	//   name: 'about',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	// }
]

const router = new VueRouter({
	routes
})

export default router
