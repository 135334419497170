import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'


import './styles/reset.less' //重置样式
import './assets/iconfont/iconfont.css' //阿里图标库
import Meta from 'vue-meta'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/js/common.js' //移动端适配common
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'


// import 'lib-flexible/flexible.js'
// import '@/utils/viewport'

Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Meta);
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
Vue.prototype.$bus = new Vue()
import myUtils from './utils/util'
Vue.prototype.$myUtils = myUtils
// import { Overlay  } from 'vant';
// Vue.use(Overlay )


//分辨率适配
window.onresize = function () {
  if (document.body.clientWidth > 800) {
    document.body.style.zoom = 1;
    document.body.style.zoom = document.body.clientWidth / 2000;
  }
}

window.onload = function() {
  if (document.body.clientWidth > 800) {
    document.body.style.zoom = 1;
    document.body.style.zoom = document.body.clientWidth / 2000;
  }
}


new Vue({
  //定义metaInfo
  metaInfo() {
    return {
      meta: [{
        name: 'keywords',
        content: this.keywords
      }, {
        name: "description",
        content: this.description
      }]
    }
  },
  router,
  // store,
  render: h => h(App)
}).$mount('#app')