import request from '../utils/request'
//获取新闻列表
export const getNewsList=(params)=>request.get('/doc/newsList',params)
// 获取新闻详情
export const getNewsDetail=(params)=>request.get('/doc/newsDetail',params)
// 获取token
export const getToken=(data)=>request.post('/r/sys/oauth',data)
// 注册&&登录
export const registerLogin=(data)=>request.post('/r/sys/oauth/login',data)

// // 验证码
// export const getCode=(data)=>request.post('/r/sso/code',data)

// saas 获取code 
export const getSaasCode=(data)=>request.post('/r/sso/oauth/code', data, {'type':'saas'})
// saas oauth
export const getSaasOauth=(data)=>request.post(data.url, data.data, {'type':'saas'})
// saas login
export const saasLogin=(data)=>request.post('/r/sso/oauth/login', data, {'type':'saas'})
// saas captchaSms
export const saasSms=(data)=>request.post('/r/sso/oauth/captchaSms', data, {'type':'saas'})
// info
export const userInfo=(data)=>request.post('/r/sys/oauth/info',data, {'type':'saas'})