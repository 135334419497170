
function validatenull(val) {
  if (typeof val === 'boolean') {
    return false;
  }

  if (typeof val === 'number') {
    return false;
  }

  if (val instanceof Array) {
    if (val.length == 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true;
  } else {
    if (val == 'null' || val == null || val == 'undefined' || val == undefined || val == '') return true;
    return false;
  }

  return false;
}

function validateMobile(phone) {
  const re = /^1(3|4|5|7|8|9|6)\d{9}$/
  return re.test(phone);
}

module.exports = {
  isEmpty: validatenull,
  validateMobile: validateMobile
};