<template>
	<div class="main">
		<div class="content">
			<div class="left">
				<div class="type" v-for="item in list" :key="item.docTitle">
					<div class="title" >
						{{ item.docTitle }}
					</div>
					<div class="item" v-for="(subItem,index) in item.children" :key="subItem.docId"
						@click="toSubDetail( item.docUri)">
						<a :href="'#maodian-0'">{{ subItem.docTitle }}</a>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="title">{{info.docTitle}}</div>
				<img :src="info.docImg" alt="" width="56px" />
				<div class="tip">{{info.docSubTitle}}</div>
			</div>
		</div>
		<div class="copyright">
			版权所有 ©2021 易雪儿科技(武汉)有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
				rel="noopener noreferrer">鄂ICP备17000861号-1</a>
		</div>
	</div>
</template>
<script>
	import {
		offDoc
	} from "../api/news.js"
	export default {
		data() {
			return {
				info: [],
				list: [],
			};
		},
		created() {
			this.getInfo();
			this.getList()
		},
		methods: {
			getInfo() {
				const data = {
					page: {
						pageNum: 1,
						pageSize: 99999,
					},
					data: {
						orgFreeId: "b4f9a873030bf5c2ab153c522c5ce9a8",
						appFreeId: "b36e3f43ece73b62aa2dadde0b92e81d",
						docId: "1760512514652770304",
					},
				};
				offDoc(data).then((res) => {
					res.data.data.forEach(item => {
						this.info = item;
					})
					console.log(this.info);
					this.total = res.total;
				}).catch((err) => {
					console.log(err)
				});
			},
			getList() {
				const data = {
					page: {
						pageNum: 1,
						pageSize: 99999,
					},
					data: {
						orgFreeId: "b4f9a873030bf5c2ab153c522c5ce9a8",
						appFreeId: "b36e3f43ece73b62aa2dadde0b92e81d",
						docId: "4692616524849090560",
					},
				};
				offDoc(data).then((res) => {
					res.data.data.forEach(item => {
						this.list = item.children;
					})
					this.total = res.total;
				}).catch((err) => {
					console.log(err)
				});
			},
			toDetail(path) {
				this.$router.push({
					path
				});
			},
			toSubDetail(path) {
				this.$router.push({
					path
				});
			}
		}
	};
</script>

<style lang="less" scoped>
	.title {
		color: #7b8ba2;
		font-size: 14px;
		margin-bottom: 22px;
		cursor: pointer;
	}

	.main {
		width: 100%;
		background: #f9fbff;
		margin-top: 20px;

		.content {
			width: 100%;
			margin: 0 auto;
			padding-bottom: 10px;
			border-bottom: 1px solid #e1e1e1;
			display: grid;
			// grid-template-columns: 247px 146px;

			.left {
				display: flex;
				justify-content: space-evenly;

				.type {
					width: 78px;
					margin: 0 auto;

					.item {
						margin-bottom: 10px;
						font-size: 8px;
						color: #c8d0db;
						cursor: pointer;
					}
				}
			}

			.right {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-left: 210px;
				margin-top: -57px;

				.tip {
					font-size: 12px;
					color: #c8d0db;
					margin-top: 10px;
				}
			}
		}
	}

	.copyright {
		text-align: center;
		color: #96a2b5;
		font-size: 12px;
		padding: 10px 0;
	}
</style>
